import React, { useCallback } from 'react';
import { Link, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Playlist from '../components/playlist';
import { addHotspot } from '../utils/imageUrl';
import { defaultSerializers } from '../utils/serializers';
import PlaylistEmbed from '../embeds/playlistEmbed';
import Img from 'gatsby-image';
import {
  FaPlay,
  FaCompactDisc,
  FaBandcamp,
  FaApple,
  FaSpotify,
  FaTwitter,
  FaFacebook,
  FaEnvelope,
} from 'react-icons/fa';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { actions } from '../store/slices/player';
import GalleryEmbed from '../embeds/galleryEmbed';
import { stringify } from 'query-string';

function AlbumShare({ title, albumSlug, trackSlug = undefined, box = false }) {
  const urlBase = `https://nicolette.me/music/album`;
  const url = trackSlug
    ? `${urlBase}/${albumSlug}/${trackSlug}/`
    : `${urlBase}/${albumSlug}/`;
  return (
    <aside
      style={{
        textAlign: box ? 'left' : 'center',
        opacity: box ? 1 : 0.7,
        fontWeight: 'bold',
        padding: box ? '1em' : 0,
        borderRadius: '6px',
        background: box ? '#fff' : 'transparent',
      }}
    >
      Share {trackSlug ? ' Song' : 'Album'}:{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share on Twitter"
        target="_blank"
        rel="noopener"
        href={`https://twitter.com/intent/tweet?${stringify({
          text: `"${title}" by @nicolettemmusic \n\n ${url}`,
        })}`}
      >
        <FaTwitter size="1.5em" color="#444" />
      </a>{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share on Facebook"
        target="_blank"
        rel="noopener"
        href={`https://www.facebook.com/sharer/sharer.php?${stringify({
          u: `${url}`,
        })}`}
      >
        <FaFacebook size="1.5em" color="#444" />
      </a>{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share by Email"
        href={`mailto:?${stringify({
          subject: title,
          body: `"${title}" by Nicolette Macleod\n\n${url}\n\n `,
        })}`}
      >
        <FaEnvelope size="1.5em" color="#444" />
      </a>{' '}
    </aside>
  );
}

const AlbumEntry = props => {
  const { data, pageContext, replaceQueue } = props;
  const {
    title,
    slug,
    albumCover,
    _rawNotes,
    tracks,
    appleMusicURL,
    bandcampURL,
    spotifyURL,
    bandcampAlbumID,
    featuredGallery,
  } = data.sanityAlbum;
  const { trackSlug } = pageContext;
  const currentTrack = trackSlug
    ? tracks.find(track => track.slug.current === trackSlug)
    : null;

  const playTrack = (queuePosition: number) =>
    useCallback(() => {
      const formattedTracks = tracks.map(({ song: { title }, sources }) => ({
        title,
        sources: sources.map(s => get(s, 'asset.url')),
      }));

      replaceQueue({ tracks: formattedTracks, queuePosition });
    }, [tracks, queuePosition]);

  return (
    <Layout
      sidebarSide="left"
      dominantColour={albumCover.asset.metadata.palette.dominant.background}
      textColour={albumCover.asset.metadata.palette.dominant.title}
      sidebar={
        <div className="album__aside">
          <Link
            title="View Album Details"
            style={{ display: 'block', margin: 'auto', maxWidth: '400px' }}
            to={`/music/album/${slug.current}/`}
          >
            <Img
              fixed={albumCover.asset.fixed}
              style={{
                display: 'block',
                marginLeft: '-20px',
                marginRight: '-20px',
                marginTop: '-20px',
              }}
            />
          </Link>
          <ol style={{ margin: '2em 0' }}>
            {/* <li
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
              }}
              key="album-details"
            >
              <FaCompactDisc color="#444" size={16} /> Album Details
            </li> */}
            {tracks.map((track, index: number) => (
              <li
                key={track.song.slug.current}
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <button
                  className="playButton"
                  onClick={playTrack(index)}
                  title={`Play ${track.song.title}`}
                >
                  <FaPlay size={12} color={'#fff'} />
                </button>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#333',
                    lineHeight: '1em',
                    padding: '5px 10px',
                  }}
                  to={`/music/album/${slug.current}/${track.song.slug.current}/`}
                >
                  <strong>{track.song.title}</strong> (lyrics)
                </Link>
              </li>
            ))}
          </ol>
          <div style={{ marginBottom: '1.5em' }}>
            <a
              className="albumBuyButton"
              target="blank"
              rel="noopener"
              href={bandcampURL}
            >
              <FaBandcamp size={24} /> Buy on Bandcamp
            </a>
            <a
              className="albumBuyButton"
              target="blank"
              rel="noopener"
              href={appleMusicURL}
            >
              <FaApple size={24} /> Buy/Listen on Apple Music
            </a>
            <a
              className="albumBuyButton"
              target="blank"
              rel="noopener"
              href={spotifyURL}
            >
              <FaSpotify size={24} /> Listen on Spotify
            </a>
          </div>
          <AlbumShare albumSlug={slug.current} title={title} />
        </div>
      }
      headerImage={
        albumCover
          ? addHotspot(albumCover.asset.fluid.src, albumCover.hotspot)
          : undefined
      }
    >
      <SEO
        title={`${
          currentTrack ? `${currentTrack.song.title} - ` : ''
        }${title} - Album - Nicolette Macleod`}
        image={
          albumCover
            ? addHotspot(albumCover.asset.fixed.src, albumCover.hotspot)
            : undefined
        }
        // bandcampAlbumID
        twitterCard={bandcampAlbumID ? 'player' : 'summary_large_image'}
        twitterPlayerWidth={400}
        twitterPlayerHeight={472}
        meta={
          bandcampAlbumID
            ? [
                {
                  name: 'twitter:player',
                  content: `https://bandcamp.com/EmbeddedPlayer/album=${bandcampAlbumID}/size=large/bgcol=ffffff/linkcol=63b2cc/artwork=small/transparent=true/`,
                },
              ]
            : undefined
        }
      />
      <article className="page-content page-content--project">
        <header>
          {/* <Link to={currentTrack ? `/music/album/${slug.current}/` : '/music'}>
            {currentTrack ? title : 'Albums'}
          </Link>{' '} */}
          <h1>{currentTrack ? currentTrack.song.title : title}</h1>
          {/* <button className="playButton" onClick={playTrack(0)}>
            <FaPlay size={12} color={'#fff'} />
          </button> */}
        </header>
        {currentTrack ? (
          <>
            {Boolean(currentTrack.notes) && (
              <p className="album-track-notes">
                <strong>Track notes:</strong>
                {currentTrack.notes}
              </p>
            )}
            <BlockContent
              blocks={currentTrack.song._rawLyrics}
              serializers={defaultSerializers}
            />
            <AlbumShare
              box
              albumSlug={slug.current}
              trackSlug={currentTrack.song.slug.current}
              title={`'${currentTrack.song.title}' from ${title}`}
            />
          </>
        ) : (
          <>
            <BlockContent blocks={_rawNotes} serializers={defaultSerializers} />
            {Boolean(featuredGallery) && (
              <GalleryEmbed gallery={featuredGallery} />
            )}
          </>
        )}
      </article>

      {/* <Link to="/music">Back to Music</Link> */}

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query($albumSlug: String!) {
    sanityAlbum(slug: { current: { eq: $albumSlug } }) {
      id
      title
      slug {
        current
      }
      appleMusicURL
      bandcampURL
      bandcampAlbumID
      spotifyURL
      tracks {
        notes
        sources {
          asset {
            url
            extension
          }
        }
        slug {
          current
        }
        song {
          slug {
            current
          }
          _rawLyrics

          title
        }
      }
      _rawNotes(resolveReferences: { maxDepth: 10 })
      featuredGallery {
        media {
          ... on SanityGalleryImage {
            image {
              asset {
                url
                metadata {
                  palette {
                    dominant {
                      background
                    }
                  }
                }
                fixed(width: 200, height: 200, toFormat: JPG) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
      albumCover {
        hotspot {
          x
          y
        }
        asset {
          metadata {
            palette {
              dominant {
                background
              }
            }
          }
          fixed(width: 400, height: 400, toFormat: JPG) {
            ...GatsbySanityImageFixed
          }
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default connect(({ player }) => ({ player }), {
  replaceQueue: actions.replaceQueue,
})(AlbumEntry);
